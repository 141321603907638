$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_animations';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.imageWrap {
    @include sassy-animate-inset-border-hover;
    @include sassy-animate-scale-hover($scale-from: 1.1, $scale-to: 1.001);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
    position: relative;
    padding: 0;

    &:after {
        content: '';
        display: block;
        padding-bottom: 85%;
    }
}

.tiles {
    display: flex;
    flex-wrap: wrap;
    padding: $sassy-spacing-small 0;
}

.tileSubTitle {
    @include sassy-font-body($size: small, $type: none);
    color: $sassy-color-iron;
    text-align: center;
    line-height: 1.25;
}

.tileLink {
    overflow: hidden;
    display: block;
}

.tileSpacing {
    margin: $sassy-spacing-x-small;
    border: 1px solid $sassy-color-dolphin;
}

.tileBig {
    flex-basis: 50%;

    .tileLink {
        position: relative;
        flex-basis: 66%;
    }

    .hasModules {
        display: flex;
    }

    .modules {
        flex-basis: 34%;
        display: flex;
        flex-direction: column;
        padding: $sassy-spacing-small;
    }

    .title {
        @include sassy-z-index(bump);
        @include sassy-font-header;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: $sassy-spacing-small;
        line-height: 2;
        color: $sassy-color-white;
        text-align: center;
        pointer-events: none;
    }
}

.tileSmall {
    position: relative;
    flex-basis: 25%;

    .title {
        @include sassy-font-body($size: small, $type: none);
        @include sassy-z-index(bump);
        position: absolute;
        top: 45%;
        left: 0;
        right: 0;
        padding: 0 $sassy-spacing-small;
        line-height: 1;
        color: $sassy-color-white;
        text-align: center;
        pointer-events: none;
    }

    .imageWrap {
        @include sassy-animate-inset-border-hover($size: 9px, $size-to: 9px);

        &:after {
            padding-bottom: 35%;
        }
    }
}

.categoryProducts {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
}

.categoryProduct {
    flex-basis: 50%;
    padding-top: $sassy-spacing-small;
    text-align: center;
}

.categoryProductImageWrap {
    position: relative;
    display: block;
    overflow: hidden;
    width: 50%;
    padding-bottom: 50%;
    margin: auto;
    border-radius: 999px;
    border: 1px solid $sassy-color-buyer-primary;
}

.categoryProductImage {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 65%;
    max-height: 65%;
    height: auto;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
}

.categoryProductTitle {
    @include sassy-font-sub-header($size: small, $type: normal);
    display: inline-block;
    padding-top: $sassy-spacing-x-small;
    line-height: 1.2;
    @include sassy-buyer-link-no-decoration;
}

@include sassy-breakpoint-below(tablet-landscape) {
    .tileBig {
        flex-basis: 100%;
    }

    .tileSmall {
        flex-basis: 50%;
    }
}

@include sassy-breakpoint-below(tablet-portrait) {
    .tiles {
        padding-bottom: 0;
    }

    .tileBig {
        .tileSpacing {
            flex-direction: column;
        }
        .categoryProducts {
            flex-direction: row;
        }
        .tileSpacing {
            margin: 0 $sassy-spacing-x-small $sassy-spacing-small;
        }
    }

    .tileSmall {
        flex-basis: 100%;

        & + .tileSmall {
            margin-bottom: $sassy-spacing-x-small;
            margin-top: -$sassy-spacing-x-small;
        }

        .tileSpacing {
            margin: 0 -#{$sassy-spacing-x-small};
        }
    }

    .categoryProduct {
        padding: $sassy-spacing-small $sassy-spacing-x-small 0;
    }

    .categoryProductImageWrap {
        width: 70%;
        padding-bottom: 70%;
    }
}
