$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.header {
    @include sassy-font-header($size: none);
    padding: $sassy-spacing-larger $sassy-spacing-medium $sassy-spacing-medium;
    text-align: center;
}

.h1 {
    @include sassy-font-header($size: xx-large, $type: none);
}

.headerParticle {
    @include sassy-font-header($size: none, $type: italic);
    padding-right: $sassy-spacing-x-small;
}

.h2 {
    @include sassy-font-header($size: medium, $type: none);
    margin: 6px 0 0;
}
