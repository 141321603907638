$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_animations';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';

.roomList {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: $sassy-spacing-large;
}

.room {
    position: relative;
    flex-basis: 25%;

    padding: $sassy-spacing-x-small;
}

.roomHeader {
    @include sassy-font-header($size: xx-large);
    text-align: center;
    padding: $sassy-spacing-small 0;
}

.roomLink {
    overflow: hidden;
    display: block;
}

.roomTitle {
    @include sassy-z-index(bump);
    @include sassy-font-header($size: medium);
    position: absolute;
    left: 0;
    right: 0;
    padding: $sassy-spacing-small;
    text-align: center;
    color: $sassy-color-noir;
    pointer-events: none;
}

.roomImage {
    @include sassy-animate-inset-border-hover;
    @include sassy-animate-scale-hover($scale-from: 1.1, $scale-to: 1.001);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
    position: relative;
    padding: 0;

    &:after {
        content: '';
        display: block;
        padding-bottom: 85%;
    }
}

@include sassy-breakpoint-below(tablet-landscape) {
    .roomList {
        padding-bottom: $sassy-spacing-small;
    }

    .room {
        flex-basis: 50%;
    }
}

@include sassy-breakpoint-below(tablet-portrait) {
    .roomTitle {
        @include sassy-font-body($type: none);
        padding: $sassy-spacing-x-small;
    }
}
